
import NavigationOffscreen from "../components/nav/navigationOffscreen.vue";

import { mapState } from "vuex";
import { mapMutations } from "vuex";
import NavigationDefault from "../components/nav/navigationDefault.vue";
import NavigationFooter from "../components/nav/navigationFooter.vue";
const apiKey = process.env.GMAP_API_KEY;

export default {
  head() {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
        },
        // {
        //   src: "https://api.herefish.com/scripts/hf.js",
        // },
      ],
    };
  },
  data() {
    return {
      scrollClass: "",
    };
  },
  name: "layoutDefault",
  mounted() {},
  methods: {
    handleScroll() {
      this.scrollClass = window.scrollY > 0 ? "scrollnav" : "";
    },
    ...mapMutations({
      setOffscreenState: "global/setOffscreenState",
    }),
  },
  created() {
    if (process.client) {
      window.addEventListener("scroll", this.handleScroll);
      window.hfAccountId = "56a46161-5bfc-4d4e-8773-b0303adf6455";
      window.hfDomain = "https://api.herefish.com";
      (function () {
        var hf = document.createElement("script");
        hf.type = "text/javascript";
        hf.async = true;
        hf.src = window.hfDomain + "/scripts/hf.js";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hf, s);
      })();
    }
  },
  computed: {
    ...mapState({
      navigationFooter(state) {
        return state.global.content.navigationFooter;
      },
      navigationMain(state) {
        return state.global.content.navigationMain;
      },
      offscreenState(state) {
        return state.global.offscreenState;
      },
      facebook_url(state) {
        return state.global.content.facebook_url;
      },
      linkedin_url(state) {
        return state.global.content.linkedin_url;
      },
      twitter_url(state) {
        return state.global.content.twitter_url;
      },
      instagram_url(state) {
        return state.global.content.instagram_url;
      },
      footer_address(state) {
        return state.global.content.footer_address;
      },
      map_link(state) {
        return state.global.content.map_link;
      },
      email(state) {
        return state.global.content.email;
      },
      phone(state) {
        return state.global.content.phone;
      },
    }),
  },
  async fetch() {
    await this.$axios
      .$post(`/api/content`, {
        query: "site",
        select: {
          title: "site.title",
          categories: "site.categories",
          footer_address: "site.footer_address",
          facebook_url: "site.facebook_url",
          instagram_url: "site.instagram_url",
          linkedin_url: "site.linkedin_url",
          navigationMain: "site.navigation_main.toNavigationArray",
          navigationFooter: "site.navigation_footer.toNavigationArray",
          email: "site.email",
          phone: "site.phone",
        },
      })
      .then((resp) => {
        this.$store.commit("global/setGlobalContent", resp.result);
      })
      .catch((e) => {
        console.log("navigation bar error", e);
      });
  },
  components: { NavigationDefault, NavigationFooter, NavigationOffscreen },
};
